:root {
  font-size: 18px;

  --gray-1: #9e9e9e;
  --gray-2: #757575;
  --gray-3: #616161;
  --gray-4: #424242;
  --gray-5: #212121;

  --background: #f2adba;
  --highlight: #f2adba;

  color: var(--gray-5);
  font-family: "Roboto Mono", monospace;
  background-color: var(--background);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

.App {
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 10px;
}


footer {

  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--gray-5);
}

a {
  color: var(--highlight);
  font-weight: 800;
  text-decoration: none;
  font-style: italic;
  padding-bottom: 8px;
  font-size: 1rem;
}

a:hover{
  text-decoration: underline;
}